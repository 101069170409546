import { createSlice } from "@reduxjs/toolkit";

export interface UserIntialState {
  id: number | null;
  // name: string | null;
  email: string | null;
  password: string | null;
  passcode: number | null;
  company?: {
    id: number | null;
    name: string | null;
    address: string | null;
    user_id: number | null;
    subscription_id: number | null;
    language: "th" | "en" | "zh";
  };
  location?: number | null;
  isAdmin: boolean | undefined;
  role: "ADMIN" | "USER" | null;
}
const initialState: UserIntialState = {
  id: null,
  // name: null,
  email: null,
  password: null,
  passcode: null,
  company: {
    id: null,
    name: null,
    address: null,
    user_id: null,
    subscription_id: null,
    language: "th",
  },
  location: null,
  isAdmin: undefined,
  role: null,
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    loggedInUser: (state, action) => {
      state.id = action.payload.id;
      // state.name = action.payload.name;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.passcode = action.payload.passcode;
      state.company = action.payload.company;
      state.isAdmin = action.payload.isAdmin;
      state.role = action.payload.role;
    },
    insertLocation: (state, action) => {
      state.location = action.payload;
    },
    loggedOutUser: (state) => {
      state.id = null;
      // state.name = null;
      state.email = null;
      state.password = null;
      state.passcode = null;
      // @ts-ignore
      state.company = null;
      state.location = null;
      state.isAdmin = false;
      state.role = null;
    },
  },
});
export const { loggedInUser, loggedOutUser, insertLocation } = user.actions;
export default user.reducer;
