import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface facilities_categories_type {
  id: number;
  name: string;
  name_translations: any;
  company_id: number | string;
}

export interface initialState {
  facilities_categories: facilities_categories_type[];
  facilities_description: string;
}

const initialState: initialState = {
  facilities_categories: [],
  facilities_description: "",
};

const FacilitiesCategoriesSlice = createSlice({
  name: "facilities_categories",
  initialState,
  reducers: {
    insertFacilitiesCategories: (state: initialState, action) => {
      state.facilities_categories = action.payload;
    },
    insetFacilities_description: (state: initialState, action) => {
      state.facilities_description = action.payload;
    },
  },
});

export const { insertFacilitiesCategories, insetFacilities_description } =
  FacilitiesCategoriesSlice.actions;

export const FacilitiesCategorieValue = (state: RootState) =>
  state.FacilitiesCategoriesSlice.facilities_categories;
export const DescriptionValue = (state: RootState) =>
  state.FacilitiesCategoriesSlice.facilities_description;

export default FacilitiesCategoriesSlice.reducer;
