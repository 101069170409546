import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialStateType {
  hotelCompanyInfo: null;
  roomInfo: any;
  currentFeedbackStept: number;
  selectedFeedbackData: defaultEmojisDataType;
}

const initialState = {
  hotelCompanyInfo: null,
  roomInfo: null,
  currentFeedbackStept: 1,
  selectedFeedbackData: {
    name: "",
    emojis: "",
    point: 0,
  },
};

const hotelCompanyInfo = createSlice({
  name: "hotelCompanyInfo",
  initialState,
  reducers: {
    insertHotelCompanyInfo: (state: InitialStateType, action: any) => {
      state.hotelCompanyInfo = action.payload;
    },
    insertPersistRoomInfo: (state, action) => {
      state.roomInfo = action.payload;
    },
    insertCurrentStept: (state, action) => {
      state.currentFeedbackStept = action.payload;
    },
    insertSelectedFeedbackData: (state, action) => {
      state.selectedFeedbackData = action.payload;
    },
  },
});

export const {
  insertHotelCompanyInfo,
  insertPersistRoomInfo,
  insertCurrentStept,
  insertSelectedFeedbackData,
} = hotelCompanyInfo.actions;

export const getHotelCompanyInfo = (state: RootState) =>
  state.hotelCompanyInfo.hotelCompanyInfo;

export const getPersistRoomInfo = (state: any) =>
  state.hotelCompanyInfo.roomInfo;

export const currentStept = (state: RootState) =>
  state.hotelCompanyInfo.currentFeedbackStept;

export const selectedFbData = (state: RootState) =>
  state.hotelCompanyInfo.selectedFeedbackData;

export default hotelCompanyInfo.reducer;
