import { createSlice } from "@reduxjs/toolkit";
import { translationType } from "../../lib/types";

interface InitialType {
  translations: translationType[];
}

const initialState: InitialType = {
  translations: [],
};

const TranslationSlicer = createSlice({
  name: "Translations",
  initialState,
  reducers: {
    storeTranslations: (state, action) => {
      state.translations = action.payload;
    },
    updateTranslations: (state, action) => {
      state.translations = state.translations.map((i) =>
        i.id === action.payload.id ? action.payload : i
      );
    },
  },
});

export const { storeTranslations,updateTranslations } = TranslationSlicer.actions;
export default TranslationSlicer.reducer;
