import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface initialState {
  houseKeepingPopUp: boolean;
  deeplinkPopUp: boolean;
  wifiPopUp: boolean;
  PromotionsPopUp: boolean;
}

const initialState: initialState = {
  houseKeepingPopUp: false,
  deeplinkPopUp: false,
  wifiPopUp: false,
  PromotionsPopUp: false,
};

const cfPopUpAction = createSlice({
  name: "cfPopUpAction",
  initialState,
  reducers: {
    inserthouseKeepingPopUp: (state: initialState) => {
      state.houseKeepingPopUp = !state.houseKeepingPopUp;
    },
    insertDeepLinkPopUp: (state: initialState, action: any) => {
      if (action.payload === "autoclose") {
        state.deeplinkPopUp = false;
      } else {
        state.deeplinkPopUp = !state.deeplinkPopUp;
      }
    },
    insertWifiPopUp: (state: initialState, action: any) => {
      if (action.payload === "autoclose") {
        state.wifiPopUp = false;
      } else {
        state.wifiPopUp = !state.wifiPopUp;
      }
    },
    insertPromotionsPopUp: (state: initialState) => {
      state.PromotionsPopUp = !state.PromotionsPopUp;
    },
  },
});

export const {
  inserthouseKeepingPopUp,
  insertDeepLinkPopUp,
  insertWifiPopUp,
  insertPromotionsPopUp,
}: any = cfPopUpAction.actions;

export const houseKeepingPopUpValue: any = (state: RootState) =>
  state.cfPopUpAction.houseKeepingPopUp;
export const deeplinkPopUpValue: any = (state: RootState) =>
  state.cfPopUpAction.deeplinkPopUp;
export const wifiPopUpValue: any = (state: RootState) =>
  state.cfPopUpAction.wifiPopUp;
export const PromotionsPopUpValue = (state: RootState) =>
  state.cfPopUpAction.PromotionsPopUp;

export default cfPopUpAction.reducer;
