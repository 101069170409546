import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import moment from "moment";
import { Alert, Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setExpired } from "../redux/reducers/expireSlice";
import { UserIntialState } from "../redux/reducers/user";
import { companyInitialType } from "../redux/reducers/company";
import { loggedOutUser } from "../redux/reducers/user";
import { emptyCompany, emptyLocation } from "../redux/reducers/company";
import { signOut } from "next-auth/react";
import { SlLogout } from "react-icons/sl";

interface AlertContextProps {
  showAlert: () => void;
}
export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
});
export const AlertProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const { companyInfo, stripe_subscription } = useSelector(
    (state: RootState) => state.company
  ) as companyInitialType;

  const user = useSelector((state: RootState) => state.user) as UserIntialState;
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [expiredDate, setExpiredDate] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const route = useRouter();
  const { location_id } = route.query;
  const dispatch = useDispatch();

  const redirectToSubscriptions = async () => {
    route.push(
      `/backoffice/location/setting/subscriptions?location_id=${location_id}`
    );
  };

  useEffect(() => {
    handleShowAlert();
  }, []);

  // remove by htetmyat  || companyInfo.stripe_customer_id
  const handleShowAlert = () => {
    if (Object.entries(stripe_subscription).length !== 0) {
      const trial_end = new Date(stripe_subscription.current_period_end * 1000);
      const stripeExpiredDate = moment(trial_end).format("DD-MMM-YYYY");
      setExpiredDate(stripeExpiredDate);
      if (
        stripe_subscription.cancel_at_period_end ||
        stripe_subscription.status == "past_due"
      ) {
        const cancelDate =
          stripe_subscription.status === "past_due"
            ? new Date(stripe_subscription.trial_end * 1000)
            : new Date(stripe_subscription.current_period_end * 1000);
        const currentDate = new Date();
        const expiredDate = moment(cancelDate).format("DD-MMM-YYYY");
        setExpiredDate(stripeExpiredDate);
        setIsCanceled(true);
        if (currentDate > cancelDate) {
          setIsExpired(true);
          dispatch(setExpired(true));
        } else {
          setIsExpired(false);
          dispatch(setExpired(false));
        }
      } else {
        const createdAt = new Date(companyInfo?.createdAt);
        let expiredDate = createdAt.getDate() + 30;
        const currentDate = new Date();
        createdAt.setDate(expiredDate);
        if (stripe_subscription?.current_period_end) {
          setExpiredDate(stripeExpiredDate);
        } else {
          setExpiredDate(moment(createdAt).format("DD-MMM-YYYY"));
        }
        if (
          !stripe_subscription.current_period_end &&
          !stripe_subscription.id
        ) {
          if (currentDate > createdAt) {
            setIsExpired(true);
            dispatch(setExpired(true));
          }
        } else if (currentDate > trial_end) {
          setIsExpired(true);
          dispatch(setExpired(true));
        } else {
          setIsExpired(false);
          dispatch(setExpired(false));
        }

        // <--- htetmayt
      }
    } else {
      setIsCanceled(false);
      const createdAt = new Date(companyInfo?.createdAt);
      let expiredDate = createdAt.getDate() + 30;
      const currentDate = new Date();
      createdAt.setDate(expiredDate);
      setExpiredDate(moment(createdAt).format("DD-MMM-YYYY"));
      if (!stripe_subscription.current_period_end && !stripe_subscription.id) {
        if (currentDate > createdAt) {
          setIsExpired(true);
          dispatch(setExpired(true));
        } else {
          setIsExpired(false);
          dispatch(setExpired(false));
        }
      }
    }
  };

  const shouldShowAlert = () => {
    const allowedRoutes = [
      "/backoffice/location/setting/subscriptions",
      "/checkout",
      "/signin",
      "/siginup",
    ];
    return allowedRoutes.includes(route.pathname);
  };

  const handleAlertClose = () => {
    return;
    // setIsExpired(false);
  };

  const handleSignOut = async () => {
    try {
      await dispatch(loggedOutUser());
      await dispatch(emptyCompany());
      await dispatch(emptyLocation());
      await signOut({ redirect: false, callbackUrl: "/signin" });
    } catch (error) {
      console.warn(error);
    }
  };

  if (shouldShowAlert()) {
    return <>{children}</>;
  }

  return (
    <AlertContext.Provider value={{ showAlert: handleShowAlert }}>
      <>
        {children}
        {isExpired && (
          <Dialog
            sx={{ zIndex: 99999 }}
            open={isExpired}
            onClose={handleAlertClose}
          >
            <Alert severity="error">
              {isCanceled ? t("canceled-alert") : t("expired-alert")}{" "}
              {expiredDate}.
            </Alert>
            <Typography className="p-4">
              {user.isAdmin
                ? t("choose-subscription-message")
                : t("contact-your-admin")}
            </Typography>
            <Box
              display={user.isAdmin ? "flex" : "none"}
              justifyContent="center"
            >
              <button
                type="button"
                onClick={redirectToSubscriptions}
                className=" w-[200px] h-[40px] mx-[15px]   active:scale-95 transition-all duration-100   hover:bg-primaryOrange/90 btn-primary my-2"
              >
                {t("ViewPlans")}
              </button>
            </Box>
            <div className="w-full flex flex-row items-center justify-center pb-[15px] pt-[10px] ">
              <div
                onClick={handleSignOut}
                className={`  w-[120px]  h-[40px] active:scale-95   rounded-[5px]
                  bg-dark/80 hover:bg-dark/90 text-white  dark:bg-dark flex flex-row z-[99]
                  items-center text-[13px] justify-center  920:text-auto cursor-pointer   
                  transition-all duration-100   px-3 py-1    text-sm font-semibold  `}
              >
                <SlLogout className={` mr-[10px] text-[20px]  text-white `} />{" "}
                {t("signout")}
              </div>
            </div>
          </Dialog>
        )}
      </>
    </AlertContext.Provider>
  );
};
