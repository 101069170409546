import { createSlice } from "@reduxjs/toolkit";

export interface StaffMenuType {
  lmmData: any[];
  categories: any[];
}

const initialState: StaffMenuType = {
  lmmData: [],
  categories: [],
};

const staffsMenu = createSlice({
  name: "StaffsMenu",
  initialState,
  reducers: {
    insertLmmData: (state, action) => {
      state.lmmData = action.payload;
    },
    insertCategories: (state, action) => {
      state.categories = action.payload;
    },
    editLMMData: (state, action) => {
      const updatedIds = action.payload.map(
        (data: { id: number; is_available: boolean }) => data.id
      );

      const updatedCategories = state.lmmData.map((data) =>
        updatedIds.includes(data.lmmId)
          ? { ...data, available: action.payload[0].is_available }
          : data
      );

      state.lmmData = updatedCategories;
    },
  },
});

export const { insertLmmData, insertCategories, editLMMData } =
  staffsMenu.actions;

export const getLmmData = (state: any) => state.staffMenuSlice.lmmData;
export const getCategories = (state: any) => state.staffMenuSlice.categories;

export default staffsMenu.reducer;
