import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  onChosenorderData: any;
  ServiceOrderArry: any;
  ChosenOrderId: any;
  selecteType: string;
  Table: any;
}

const initialState: initialStateType = {
  onChosenorderData: [],
  ServiceOrderArry: [],
  ChosenOrderId: "",
  selecteType: "All",
  Table: {},
};

const onChosenorde = createSlice({
  name: "onChosenorde",
  initialState,
  reducers: {
    insertOnChosenorder: (state: initialStateType, action: any) => {
      state.onChosenorderData = action.payload;
    },
    insertValidServiceOrderArry: (state: initialStateType, action: any) => {
      state.ServiceOrderArry = action.payload;
    },
    insertOnChosenId: (state: initialStateType, action: any) => {
      state.ChosenOrderId = action.payload;
    },
    insertTable: (state: initialStateType, action: any) => {
      state.Table = action.payload;
    },
    insertSelecteType: (
      state: initialStateType,
      action: PayloadAction<string>
    ) => {
      state.selecteType = action.payload;
    },
  },
});

export const {
  insertOnChosenorder,
  insertValidServiceOrderArry,
  insertOnChosenId,
  insertTable,
  insertSelecteType,
} = onChosenorde.actions;

export const getOnChosenorder = (state: RootState) =>
  state.onChosenorde.onChosenorderData;
export const getValidServiceOrderArry = (state: RootState) =>
  state.onChosenorde.ServiceOrderArry;
export const OnChosenId = (state: RootState) =>
  state.onChosenorde?.ChosenOrderId;
export const getTable = (state: RootState) => state.onChosenorde?.Table;
export const SelecteTypeValue = (state: RootState) =>
  state.onChosenorde?.selecteType;

export default onChosenorde.reducer;
