import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  popUpOpen: boolean;
}

const initialState: initialStateType = {
  popUpOpen: true,
};

const CfPopUpReducer = createSlice({
  name: "CfPopUpReducer",
  initialState,
  reducers: {
    insertCfPopUpStatus: (
      state: initialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.popUpOpen = action.payload;
    },
  },
});

export const { insertCfPopUpStatus } = CfPopUpReducer.actions;

export const getPopUpStatus = (state: RootState) =>
  state.CfPopUpReducer.popUpOpen;

export default CfPopUpReducer.reducer;
