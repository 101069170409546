import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface staffType {
  staff_data: staffDataType | any;
}

const initialState: staffType = {
  staff_data: {},
};

const Staff = createSlice({
  name: "staff",
  initialState,
  reducers: {
    insertStaffData: (
      state: staffType,
      action: PayloadAction<staffDataType | {}>
    ) => {
      state.staff_data = action.payload;
    },
  },
});

export const { insertStaffData } = Staff.actions;

export const staffDataValue = (state: RootState) => state.staff.staff_data;

export default Staff.reducer;
