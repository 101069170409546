"use client";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

const Loader = () => {
  const { asPath } = useRouter();

  return (
    <div className="fixed top-0 left-0 w-screen bg-grey h-screen flex justify-center items-center z-[999]">
      {asPath.startsWith("/order") || asPath.startsWith("/hotel") ? (
        <span className="loader1"></span>
      ) : (
        <span className="loader1"></span>
      )}
    </div>
  );
};

export default Loader;
