import { createSlice } from "@reduxjs/toolkit";
export interface OrderLocationInfoInitialStateType {
  location: number;
  table: number;
  orderId: number;
}

const initialState: OrderLocationInfoInitialStateType = {
  location: 0,
  table: 0,
  orderId: 0,
};

const orderLocationInfo = createSlice({
  name: "orderLocationInfo",
  initialState,
  reducers: {
    addTable: (state, action) => {
      state.table = action.payload.table;
      state.location = action.payload.location;
    },
    insertOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    removeOrderId: (state) => {
      state.orderId = 0;
    },
  },
});

export const { addTable, insertOrderId, removeOrderId } =
  orderLocationInfo.actions;

export default orderLocationInfo.reducer;
