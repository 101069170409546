import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface facilities_items_type {
  id: number;
  image_url: string;
  name: string;
  description: string;
  link_name: string;
  destination: number;
  name_translations: any;
  description_translations: any;
  link_name_translations: any;
  url_or_pdf: any;
  categories_id_array: any;
  company_id: number;
}

export interface initialState {
  facilities_items: facilities_items_type[];
  itemDetail: any;
  filterItem: facilities_items_type[];
  updateItem: any;
  selectedTab: string;
}

const initialState: initialState = {
  facilities_items: [],
  updateItem: {},
  itemDetail: {},
  filterItem: [],
  selectedTab: "",
};

const FacilitiesItemsSlice = createSlice({
  name: "facilities_items",
  initialState,
  reducers: {
    insertFacilitiesItems: (state: initialState, action) => {
      state.facilities_items = action.payload;
    },
    insertUpdateItem: (state: initialState, action: PayloadAction<any>) => {
      state.updateItem = action.payload;
    },
    insertItemDetail: (state: initialState, action: PayloadAction<any>) => {
      state.itemDetail = action.payload;
    },
    insertFilterItems: (state: initialState, action: PayloadAction<any>) => {
      state.filterItem = action.payload;
    },
    insetselectedTabValue: (
      state: initialState,
      action: PayloadAction<any>
    ) => {
      state.selectedTab = action.payload;
    },
  },
});

export const {
  insertFacilitiesItems,
  insertItemDetail,
  insertFilterItems,
  insertUpdateItem,
  insetselectedTabValue,
} = FacilitiesItemsSlice.actions;

export const FacilitiesItemsValue = (state: RootState) =>
  state.FacilitiesItemsSlice?.facilities_items;
export const detailItemsValue = (state: RootState) =>
  state.FacilitiesItemsSlice.itemDetail;
export const filterItemsValue = (state: RootState) =>
  state.FacilitiesItemsSlice.filterItem;
export const updateItemValue = (state: RootState) =>
  state.FacilitiesItemsSlice.updateItem;
export const selectedtabValue = (state: RootState) =>
  state.FacilitiesItemsSlice.selectedTab;

export default FacilitiesItemsSlice.reducer;
