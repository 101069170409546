import { createSlice } from "@reduxjs/toolkit";

export interface ThemeStateType {
  darkMode: boolean;
}

const initialState: ThemeStateType = {
  darkMode: false,
};

const theme = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    toggleDarkMode: (state) => {
      state.darkMode = true
    },
    toggleLightMode: (state) => {
      state.darkMode = false
    }
  },
});

export const { setDarkMode, toggleDarkMode, toggleLightMode } = theme.actions;

export default theme.reducer;
