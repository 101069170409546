import { createSlice } from "@reduxjs/toolkit";
export interface OrderLanguageInfoInitialStateType {
  selectedLanguage?: "th" | "en" | "zh" | "ja" | "ko" | "ar" | "";
}

const initialState: OrderLanguageInfoInitialStateType = {
  selectedLanguage: "",
};

const orderLanguageInfo = createSlice({
  name: "orderLanguageInfo",
  initialState,
  reducers: {
    insertSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const { insertSelectedLanguage } = orderLanguageInfo.actions;

export default orderLanguageInfo.reducer;
