import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/index";

const RootApi = createApi({
  reducerPath: "Api",
  tagTypes: ["update"],

  baseQuery: fetchBaseQuery({
    baseUrl: config.NEXT_PUBLIC_API_BASE_URL,
  }),

  endpoints: (builder) => ({}),
});

export default RootApi;
