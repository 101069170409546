import { createSlice } from "@reduxjs/toolkit";

export interface KdsInitialStateType {
  type: string;
}

const initialState: KdsInitialStateType = {
  type: "",
};

const kds = createSlice({
  name: "kds",
  initialState,
  reducers: {
    changeType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { changeType } = kds.actions;

export default kds.reducer;
