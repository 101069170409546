import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CartInitialStateType {
  cart: [];
  total: number;
  amount: number;
  count: number;
  finalCost: number;
  addOns: [];
  addOnsTotal: number;
  hotelInfo?: {
    userName: string;
    roomNumber: number;
  };
  currentSelectedItems: any[];
  reminderData: any;
}

interface Cart {
  id: number;
  image: string;
  name: string;
  price: number;
  quantity: number;
  description: string;
  addOns: [];
  addOnsTotal: number;
}

export interface addOns {
  categoryId: number;
  addonId: number;
  addonName: string;
  addonPrice: number;
  radio: boolean;
  name: string;
  checkbox: boolean;
}

const initialState: CartInitialStateType = {
  cart: [],
  finalCost: 0,
  count: 1,
  total: 0,
  amount: 0,
  addOns: [],
  addOnsTotal: 100,
  hotelInfo: {
    userName: "",
    roomNumber: 0,
  },
  currentSelectedItems: [],
  reminderData: null,
};

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addNewItemToCart: (state, action) => {
      const item: any = action.payload;
      const items: any = state.cart.find(
        (cartItem: any) => cartItem.menuItem.id === item.menuItem.id
      );
      const _items: any = state.cart.find(
        (cartItem: any) =>
          cartItem.menuItem.id === item.menuItem.id &&
          cartItem?.description?.title === item.description.title
      );

      if (
        items &&
        item.description.title.length <= 0 &&
        item?.addOns?.length === 0 &&
        _items?.description?.title.length <= 0
      ) {
        _items.quantity += 1;
        state.amount += 1;
        state.total += items.price + items.addOnsTotal;
      } else {
        // @ts-ignore
        state.cart.push(item);
        state.amount += action.payload.quantity;
        state.total +=
          item.price * action.payload.quantity +
          state.addOnsTotal * action.payload.quantity;
        state.count = 1;
        state.finalCost = 0;
      }
    },
    updateCart: (state, action) => {
      const index = state.cart.findIndex(
        (c: any) => c.id === action.payload.id
      );
      const check: any = state.cart.find(
        (c: any) => c.id === action.payload.id
      );

      state.finalCost = 0;
      state.count = 1;

      if (check) {
        state.total -= check.quantity * check.price;
        state.amount -= check.quantity;
        state.total -= action.payload.prevAddonTotal * check.quantity;

        // @ts-ignore
        state.cart = state.cart.filter((s: any) => s.id !== action.payload.id);

        // @ts-ignore
        state.cart.push(action.payload);
        // @ts-ignore
        delete state.cart.prevAddonTotal;
        state.amount += action.payload.quantity;
        state.total +=
          action.payload.price * action.payload.quantity +
          state.addOnsTotal * action.payload.quantity;

        const origin: never[] | undefined = state.cart.filter(
          (c: any) => c.id === action.payload.id
        );
        const filterdata: any = state.cart.filter(
          (c: any) => c.id !== action.payload.id
        );
        if (filterdata.length) {
          state.cart = filterdata;
        }
        if (origin && origin.length > 0 && filterdata.length) {
          state.cart.splice(index, 0, ...origin);
        }
      }
    },
    updateCartWithoutAddon: (state, action) => {
      const check: any = state.cart.find(
        (c: any) => c.id === action.payload.id
      );

      if (check) {
        state.total -= check.quantity * check.price;
        state.amount -= check.quantity;
        state.total -= action.payload.prevAddonTotal * check.quantity;

        // @ts-ignore
        state.cart = state.cart.filter((s: any) => s.id !== action.payload.id);

        // @ts-ignore
        state.cart.push(action.payload);
        // @ts-ignore
        delete state.cart.prevAddonTotal;
        state.amount += action.payload.quantity;
        state.total +=
          action.payload.price * action.payload.quantity +
          state.addOnsTotal * action.payload.quantity;
      }
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    increaseCount: (state) => {
      state.count++;
    },
    decreaseCount: (state) => {
      if (state.count > 0) {
        state.count--;
      }
    },
    setFinalCost: (state, action: PayloadAction<number>) => {
      state.finalCost = action.payload;
    },
    increase: (state, action) => {
      const existItem: Cart | any = state.cart.find(
        // @ts-ignore
        (f: Cart) => f.id === action.payload.id
      );
      if (existItem) {
        existItem.quantity += 1;
        state.amount += 1;
        state.total += existItem.price + existItem.addOnsTotal;
      }
    },
    decrease: (state, action) => {
      const existItem: Cart | any = state.cart.find(
        // @ts-ignore
        (f: Cart) => f.id === action.payload.id
      );
      if (existItem) {
        existItem.quantity -= 1;
        state.amount -= 1;
        state.total -= existItem.price + existItem.addOnsTotal;
      }
      if (existItem?.quantity === 0) {
        // @ts-ignore
        state.cart = state.cart.filter(
          // @ts-ignore
          (s) => s.id !== action.payload.id
        );
      }
    },
    remove: (state, action) => {
      const existItem: Cart | any = state.cart.find(
        // @ts-ignore
        (f: Cart) => f.menuItem.menu_items_id === action.payload.id
      );
      if (existItem) {
        const price = existItem.price * existItem.quantity;
        state.total -= price;
        state.amount -= existItem.quantity;
        existItem.quantity = 0;
        // @ts-ignore
        state.cart = state.cart.filter(
          // @ts-ignore
          (s) => s.menuItem.menu_items_id !== existItem.id
        );
      }
    },
    emptyCart: (state) => {
      state.cart = [];
      state.amount = 0;
      state.total = 0;
    },
    addAddOn: (state, action) => {
      const isDuplicate = state.addOns.find(
        (a: addOns) => a.addonId === action.payload.addonId
      );

      if (isDuplicate) {
        return;
      } else {
        if (action.payload.radioCheckbox) {
          if (!isDuplicate) {
            // @ts-ignore
            state.addOns = [...state.addOns, action.payload];
            state.addOnsTotal += Number(action.payload.addonPrice);
          }
          return;
        }

        if (action.payload.radio) {
          const check: addOns | any = state.addOns.find(
            (a: addOns) => a.name === action.payload.name
          );
          if (check) {
            state.addOnsTotal -= Number(check.addonPrice);
            // @ts-ignore
            state.addOns = state.addOns.filter(
              (c: any) => c.name !== action.payload.name
            );
            // @ts-ignore
            state.addOns.push(action.payload);
            state.addOnsTotal += Number(action.payload.addonPrice);
          } else {
            // @ts-ignore
            state.addOns.push(action.payload);
            state.addOnsTotal += Number(action.payload.addonPrice);
          }
        }

        if (action.payload.checkbox) {
          // @ts-ignore
          state.addOns.push(action.payload);
          state.addOnsTotal += Number(action.payload.addonPrice);
        }
      }
    },

    removeAddOn: (state, action) => {
      // @ts-ignore
      state.addOns = state.addOns.filter(
        (s: addOns) => s.addonId !== action.payload.addonId
      );
      state.addOnsTotal -= Number(action.payload.addonPrice);
    },
    emptyAddOns: (state) => {
      state.addOns = [];
      state.addOnsTotal = 0;
    },
    insertHotelOrderInfo: (state, action) => {
      state.hotelInfo = action.payload;
    },
    insertCurrentSelectedItem: (state, action) => {
      if (state.currentSelectedItems?.length) {
        state.currentSelectedItems = [
          action.payload,
          ...state.currentSelectedItems,
        ];
      } else {
        state.currentSelectedItems = [action.payload];
      }
    },
    removeCurrentSelectedItem: (state, action) => {
      if (action?.payload === 0) {
        state.currentSelectedItems = [];
      } else {
        state.currentSelectedItems = state.currentSelectedItems?.filter(
          (item) => item?.id !== action?.payload
        );
      }
    },
    insertReminderData: (state, action) => {
      state.reminderData = action.payload;
    },
  },
});

export const getTotal = (state: RootState) => {
  const rotalRawValue = state.cart.cart
    ?.map((item: any) => +item?.menuItem?.menu_items?.price * item?.quantity)
    ?.reduce((acc, current) => acc + current, 0);
  const choiceRawValue = state.cart.cart
    ?.map((item: any) =>
      item?.addOns?.map((addon: any) => +addon?.addonPrice * item?.quantity)
    )
    ?.flat()
    ?.reduce((acc, current) => acc + current, 0);
  return rotalRawValue + choiceRawValue;
};
export const getCurretSelecteItemTotal = (state: RootState) => {
  const rotalRawValue = state.cart.currentSelectedItems
    ?.map((item: any) => +item?.menuItem?.menu_items?.price * item?.quantity)
    ?.reduce((acc, current) => acc + current, 0);
  const choiceRawValue = state.cart.currentSelectedItems
    ?.map((item: any) =>
      item?.addOns?.map((addon: any) => +addon?.addonPrice * item?.quantity)
    )
    ?.flat()
    ?.reduce((acc, current) => acc + current, 0);
  return rotalRawValue + choiceRawValue;
};

export const currentSelectedItems = (state: RootState) =>
  state.cart.currentSelectedItems;
export const getReminderData = (state: RootState) => state.cart.reminderData;
// const selectedOptionsPrice = state.cart.cart?.map((item: any) => {
//   item?.menuItem?.menu_items?.price;
// });

export const {
  // addToCart,
  updateCart,
  increase,
  decrease,
  setFinalCost,
  remove,
  emptyCart,
  addAddOn,
  removeAddOn,
  emptyAddOns,
  insertHotelOrderInfo,
  addNewItemToCart,
  increaseCount,
  decreaseCount,
  setCount,
  insertCurrentSelectedItem,
  removeCurrentSelectedItem,
  insertReminderData,
} = cart.actions;

export default cart.reducer;
