import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface table_Data {
  table_Id: string;
  tableName: string;
  type: string;
}

export interface initialState {
  TablesData: any;
  table: table_Data;
}
const initialState: initialState = {
  TablesData: [],
  table: {
    table_Id: "",
    tableName: "",
    type: "",
  },
};
const HotelPosSlice = createSlice({
  name: "HotelPosSlice",
  initialState,
  reducers: {
    insertTableData: (state: initialState, action) => {
      state.TablesData = action.payload;
    },
    insertTable: (state: initialState, action) => {
      state.table = action.payload;
    },
  },
});
export const { insertTableData, insertTable } = HotelPosSlice.actions;
export const Tables = (state: RootState) => state.HotelPosSlice.TablesData;
export const tableVlaue = (state: RootState) => state.HotelPosSlice.table;

export default HotelPosSlice.reducer;
