import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface initialState {
  upSellData: any;
}

const initialState: initialState = {
  upSellData: [],
};

const UpsellReducer = createSlice({
  name: "UpsellReducer",
  initialState,
  reducers: {
    insertUpSellData: (state: initialState, action: PayloadAction<any[]>) => {
      state.upSellData = action.payload;
    },
    createUpSellData: (state: initialState, action: PayloadAction<any[]>) => {
      if (state.upSellData) {
        state.upSellData = [action?.payload, ...state.upSellData];
      } else {
        state.upSellData = action.payload;
      }
    },
    updateUpSellData: (state: initialState, action: PayloadAction<any>) => {
      if (state.upSellData) {
        const updateData = state.upSellData?.map((data: any) => {
          if (data?.id === action.payload?.id) {
            return action.payload;
          } else {
            return data;
          }
        });
        state.upSellData = updateData;
      } else {
        state.upSellData = action.payload;
      }
    },
  },
});

export const { insertUpSellData, createUpSellData, updateUpSellData } =
  UpsellReducer.actions;

export const upSellValue = (state: RootState) => state.UpsellReducer.upSellData;

export default UpsellReducer.reducer;
