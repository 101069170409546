import { createSlice } from "@reduxjs/toolkit";
import { serviceOrderType, tableType } from "../../lib/types";
import { RootState } from "../store";

export interface PaymentIdInitialStateType {
  tables: tableType[];
  mode: string;
}

interface initialStateType {
  tables: [];
  mode: "";
  currentTable: any;
}

const initialState: initialStateType = {
  tables: [],
  mode: "",
  currentTable: {},
};
//
const Tables = createSlice({
  name: "Tables",
  initialState,
  reducers: {
    insertTables: (state, action) => {
      state.tables = action.payload;
    },
    updateTableServiceOrders: (state, action) => {
      state.tables.map((table: tableType) => {
        if (table.id === action.payload.table_id) {
          table.serviceOrders.map((serviceOrder: serviceOrderType) => {
            serviceOrder.id === action.payload.service_id
              ? { ...serviceOrder, status: action.payload.serviceStatus }
              : serviceOrder;
          });
        }
      });
    },
    addCheckmark: (state: any, action) => {
      const TempArry: any = [];
      state.tables.map((i: any, index: any) => {
        i.orders.map((o: any, index_2: any) => {
          if (o.table_id === action.payload.table_id) {
            o.orderlines.map((order_line: any, order_line_index: any) => {
              if (order_line.id === action.payload.id) {
                TempArry.push(index, index_2, order_line_index);
              }
            });
          }
        });
      });

      if (TempArry.length) {
        state.tables[TempArry[0]].orders[TempArry[1]].orderlines[
          TempArry[2]
        ].status = action.payload.status;
      }
    },
    addAllCheckMark: (state: any, action) => {
      state.tables.map((i: any, index: any) => {
        i.orders.map((o: any, index_2: any) => {
          o.orderlines.map((order_line: any, order_line_index: any) => {
            state.tables[index].orders[index_2].orderlines[
              order_line_index
            ].status = action.payload.status;
          });
        });
      });
    },
    updateServicesOrder: (state: any, action) => {
      state.tables.map((table: any, table_index: any) => {
        table.serviceOrders?.map((service_order: any, index: number) => {
          if (service_order?.id === action.payload.id) {
            state.tables[table_index].serviceOrders[index].status =
              action.payload.status;
          }
        });
      });
    },
    insetrCurrentTable: (state: initialStateType, action: any) => {
      state.currentTable = action.payload;
    },
    updateMode: (state: any, action) => {
      state.mode = action.payload;
    },
  },
});

export const {
  insertTables,
  addCheckmark,
  addAllCheckMark,
  updateTableServiceOrders,
  updateServicesOrder,
  updateMode,
  insetrCurrentTable,
} = Tables.actions;

export const getTables_data = (state: any) => state.Tables.tables;

export const getMode = (state: any) => state.Tables.mode;

export const currentTableValue = (state: RootState) =>
  state?.Tables.currentTable;

export default Tables.reducer;
