import { createSlice } from "@reduxjs/toolkit";

export interface ThemeStateType {
  temporyOrder_data: any[];
}

const initialState: ThemeStateType = {
  temporyOrder_data: [],
};

const temporyOrder = createSlice({
  name: "temporyOrder",
  initialState,
  reducers: {
    settemporyOrder_data: (state, action) => {
      state.temporyOrder_data = action.payload;
    },
    removetemporyOrder_data: (state, action) => {
      const data = state.temporyOrder_data.filter(
        (items) => items.id !== action.payload
      );
      state.temporyOrder_data = data;
    },
    cleartemporyOrder_data: (state, action) => {
      state.temporyOrder_data = [];
    },
  },
});

export const {
  settemporyOrder_data,
  removetemporyOrder_data,
  cleartemporyOrder_data,
} = temporyOrder.actions;

export const TemporyOrder_data = (state: any) =>
  state.temporyOrder.temporyOrder_data;

export default temporyOrder.reducer;
