import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialStateType {
  hotelSelectedTranslation: string;
  houseKeeping: any;
  minibar: Service;
  maintenance: Service;
  roomService: Service;
}

const initialState: any = {
  hotelSelectedTranslation: "no",
  houseKeeping: null,
  minibar: null,
  maintenance: null,
  roomService: null,
  showVoidModal: false,
};

const HotelServiceSlice = createSlice({
  name: "hotelService",
  initialState,
  reducers: {
    insertHouseKeeping: (state, action) => {
      state.houseKeeping = action.payload;
    },
    insertMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    insertRoomService: (state, action) => {
      state.roomService = action.payload;
    },
    insertMinibar: (state, action) => {
      state.minibar = action.payload;
    },
    setHotelSelectedTranslation: (state, action) => {
      state.hotelSelectedTranslation = action.payload;
    },
    insertVoidModal: (state, action) => {
      state.showVoidModal = action.payload;
    },
  },
});

export const {
  insertHouseKeeping,
  insertMinibar,
  insertMaintenance,
  insertRoomService,
  setHotelSelectedTranslation,
  insertVoidModal,
} = HotelServiceSlice.actions;

export const getRoomServiceData = (state: RootState) =>
  state.hotelService.roomService;
export const getMaintenanceData = (state: RootState) =>
  state.hotelService.maintenance;
export const getHousekeepingData = (state: RootState) =>
  state.hotelService.houseKeeping;
export const getHotelSelectedTranslation = (state: RootState) =>
  state.hotelService.hotelSelectedTranslation;
export const getMinibar = (state: RootState) => state.hotelService.minibar;

export const showVoidModalValue = (state: RootState) =>
  state.hotelService.showVoidModal;

export default HotelServiceSlice.reducer;
