import { createSlice } from "@reduxjs/toolkit";

export interface ThemeStateType {
  isPos: boolean;
}

const initialState: ThemeStateType = {
  isPos: true,
};

const theme = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeDisplay: (state, action) => {
      state.isPos = action.payload;
    },
  },
});

export const { changeDisplay } = theme.actions;

export default theme.reducer;
