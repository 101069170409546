import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface initialState {
  menuCategories?: any[];
}

const initialState: initialState = {
  menuCategories: [],
};

const menuCategorieSlice = createSlice({
  name: "menuCategories",
  initialState,
  reducers: {
    insertMenuCategories: (state: initialState, action) => {
      state.menuCategories = action.payload;
    },
  },
});

export const { insertMenuCategories } = menuCategorieSlice.actions;

export const menuCategoriesValue = (state: RootState) =>
  state.MenuCategorieSlice.menuCategories;

export default menuCategorieSlice.reducer;
