import { createSlice } from "@reduxjs/toolkit";
import { orderType } from "../../lib/types";
import { RootState } from "../store";

export interface MenuInitialStateType {
  orders: orderType[];
  orderId: number | null;
  updateOrder: Object;
  fetchData: boolean;
}

const initialState: MenuInitialStateType = {
  orders: [],
  orderId: null,
  updateOrder: {},
  fetchData: false,
};

const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    insertOrders: (state, action) => {
      state.orders = action.payload;
    },
    addAllCheckMark_Order: (state: any, action) => {
      state.orders.map((o: any, index_2: any) => {
        o.orderlines.map((order_line: any, order_line_index: any) => {
          state.order[index_2].orderlines[order_line_index].status =
            action.payload.status;
        });
      });
    },
    insertOrderId: (state: any, action) => {
      state.orderId = action.payload;
    },
    insertUpdateOrder: (state: any, action) => {
      state.updateOrder = action.payload;
    },
    insertFetchData: (state: any, action) => {
      state.fetchData = action.payload;
    },
  },
});

export const {
  insertOrders,
  addAllCheckMark_Order,
  insertOrderId,
  insertUpdateOrder,
  insertFetchData,
} = order.actions;

export const getOrderId = (state: RootState) => state.orders.orderId;

export const updateOrderValue = (state: RootState) => state.orders.updateOrder;

export const getFetchData = (state: RootState) => state.orders.fetchData;

export default order.reducer;
