import { createSlice } from "@reduxjs/toolkit";
import { Log, User } from "../../types";

interface InitialStateType {
  logs: Log[] | null;
}

const initialState: InitialStateType = {
  logs: null,
};

const LogsSlice = createSlice({
  name: "Logs",
  initialState,
  reducers: {
    storeLogs: (state, action) => {
      state.logs = action.payload;
    },
  },
});
export const { storeLogs } = LogsSlice.actions;
export default LogsSlice.reducer;
