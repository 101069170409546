import axios from "axios";

import { getStorageItem } from "../functions/localStorage";
import { config } from "../config";

export const baseInstance = axios.create({
  baseURL: config.NEXT_PUBLIC_API_BASE_URL,
  withCredentials: true,
  headers: {
    "content-type": "application/json",
  },
});
