interface Config {
  environment: string;
  host: string;
  port: number;
  auth: {
    user: string;
    pass: string;
  };
  email: string;
  bucket: string;
  spaceUrl: string;
  spaceId: string;
  spaceSecret: string;
  POSTMARK_API_TOKEN: string;
  NEXT_PUBLIC_API_BASE_URL: string;
  NEXT_PUBLIC_BASE_URL: string;
  JWT_SECRET: string;
  STRIPE_SECRET_KEY: string;
  STRIPE_WEBHOOK_SECRET: string;
  PUSHER_APP_ID: string;
  PUSHER_APP_KEY: string;
  PUSHER_APP_SECRET: string;
  PUSHER_APP_CLUSTER: string;
  PUSHER_CHANNEL: string;
  PUSHER_CLIENT_KEY: string;
  NEXT_PUBLIC_SERVER_2_URL: string;
  REMINDER_SERVER_URL: string;
  REQUEST_ORIGIN_1: string;
  refreshSecret: string;
}

export const config: Config = {
  environment: process.env.NODE_ENV || "",
  host: process.env.SMTP_HOST || "",
  port: Number(process.env.SMTP_PORT),
  auth: {
    user: process.env.POSTMARK_API_TOKEN || "",
    pass: process.env.POSTMARK_API_TOKEN || "",
  },
  email: process.env.SMTP_FROM || "",
  bucket: process.env.DO_SPACES_BUCKET || "",
  spaceUrl: process.env.DO_SPACES_URL || "",
  spaceId: process.env.DO_SPACES_ID || "",
  spaceSecret: process.env.DO_SPACES_SECRET || "",
  POSTMARK_API_TOKEN: process.env.POSTMARK_API_TOKEN || "",
  NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL || "",
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL || "",
  JWT_SECRET: process.env.JWT_SECRET || "",
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY || "",
  STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET || "",
  PUSHER_APP_ID: process.env.PUSHER_APP_ID || "",
  PUSHER_APP_KEY: process.env.PUSHER_APP_KEY || "",
  PUSHER_APP_SECRET: process.env.PUSHER_APP_SECRET || "",
  PUSHER_APP_CLUSTER: process.env.PUSHER_APP_CLUSTER || "",
  PUSHER_CHANNEL: process.env.NEXT_PUBLIC_PUSHER_CHANNEL || "",
  PUSHER_CLIENT_KEY: process.env.NEXT_PUBLIC_PUSHER_CLIENT_KEY || "",
  NEXT_PUBLIC_SERVER_2_URL: process.env.NEXT_PUBLIC_SERVER_2_URL || "",
  REMINDER_SERVER_URL: process.env.NEXT_PUBLIC_REMINDER_SERVER_URL || "",
  REQUEST_ORIGIN_1: process.env.REQUEST_ORIGIN_1 || "",
  refreshSecret: process.env.REFRESH_TOKEN_SECRET || "",
};
