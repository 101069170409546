import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface drawerDataPassPropsType {
  housekeeping_maintence_data: any;
  drawer_table_data: any;
}

const initialState: drawerDataPassPropsType = {
  housekeeping_maintence_data: {},
  drawer_table_data: {},
};

const drawerDataPassProps = createSlice({
  name: "drawerDataPassProps",
  initialState,
  reducers: {
    insrtDrawerData: (
      state: drawerDataPassPropsType,
      action: PayloadAction<housekeepig_data>
    ) => {
      state.housekeeping_maintence_data = action.payload;
    },
    insertDrawerTableData: (
      state: drawerDataPassPropsType,
      action: PayloadAction<tableDataType>
    ) => {
      state.drawer_table_data = action.payload;
    },
  },
});

export const { insrtDrawerData, insertDrawerTableData } =
  drawerDataPassProps.actions;

export const DrawerDataValue = (state: RootState) =>
  state.drawerDataPassProps.housekeeping_maintence_data;
export const DrawerTableValue = (state: RootState) =>
  state.drawerDataPassProps.drawer_table_data;

export default drawerDataPassProps.reducer;
