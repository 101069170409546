import React from "react";
import error_fox from "../../../public/err_fox.png";
import Image from "next/image";
import { useTranslation } from "react-i18next";

const AFError = () => {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 left-0 w-full h-[100vh] flex flex-col items-center justify-center">
      <Image alt="error_fox" src={error_fox} width={300} />
      <div className="space-y-[10px] sm:px-0 px-[20px]">
        <h1 className="text-[20px] font-medium">Ooops...</h1>
        <h1 className="text-[25px] font-bold">{t("something-wrong")}</h1>
        <h1 className="text-[17x] font-medium">
          {`${t("try-again-contact")} support@innorder.com`}
        </h1>
      </div>
    </div>
  );
};

export default AFError;
