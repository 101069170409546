import { createSlice } from "@reduxjs/toolkit";

export interface MenuInitialStateType {
  categories: [];
  menus: [];
  filterItems: [];
  company: {
    id: number | null;
    name: string | null;
    address: string | null;
    image_url: string | null;
    cover_photo: string | null;
    service_charge_rate: number;
    tax_rate: number;
    user_id: number | null;
    type: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  };
  locationPhoneNumber: string;
}

const initialState: MenuInitialStateType = {
  categories: [],
  menus: [],
  filterItems: [],
  company: {
    id: null,
    name: null,
    address: null,
    cover_photo: null,
    image_url: null,
    service_charge_rate: 0,
    tax_rate: 0,
    user_id: null,
    type: null,
    createdAt: null,
    updatedAt: null,
  },
  locationPhoneNumber: "",
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    insertCategories: (state, action) => {
      state.categories = action.payload;
    },
    insertMenus: (state, action) => {
      state.menus = action.payload;
    },
    insertCompany: (state, action) => {
      state.company = action.payload;
    },
    insertLocationPhoneNumber: (state, action) => {
      state.locationPhoneNumber = action.payload;
    },
  },
});

export const {
  insertCategories,
  insertMenus,
  insertLocationPhoneNumber,
  insertCompany,
} = menu.actions;

export default menu.reducer;
