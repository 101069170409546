import { createSlice } from "@reduxjs/toolkit";
import { staffType } from "../../lib/types";
import staff from "../../pages/api/staff";

export interface StaffStateType {
  staffs: staffType[];
}

const initialState: StaffStateType = {
  staffs: [],
};

const staffs = createSlice({
  name: "Staffs",
  initialState,
  reducers: {
    insertStaffs: (state, action) => {
      state.staffs = action.payload;
    },
    addStaff: (state, action) => {
      state.staffs = [...state.staffs, action.payload];
    },
    updateStaffs: (state, action) => {
      const newArr = state.staffs.filter(
        (staff) => staff.id === action.payload.id
      );
      state.staffs = [...newArr, action.payload];
    },
  },
});

export const { insertStaffs, addStaff, updateStaffs } = staffs.actions;

export const getStaffs = (state: any) => state.Staffs.staffs;

export default staffs.reducer;
