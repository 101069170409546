import { createSlice } from "@reduxjs/toolkit";

export interface SideBarType {
  loading_data: {
    isLoading: boolean;
    TableId: string;
  };
};

const initialState: SideBarType = {
  loading_data: {
    isLoading: false,
    TableId : ''
  },
};

const MoveOrder = createSlice({
  name: "MoveOrder",
  initialState,
  reducers: {
    isLoadingEngin: (state , action) => {
        state.loading_data = action.payload;
    },
  },
});

export const { isLoadingEngin } = MoveOrder.actions;

export const MoveOrderLoading = (state: any) => state.MoveOrder.loading_data;

export default MoveOrder.reducer;