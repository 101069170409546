import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface hotel_Cf_DataType {
  dragDropData: dragDropType[];
  maintance_disable: boolean;
  housekeeping_disable: boolean;
  Feedback_disable: boolean;
  RoomServices_disable: boolean;
  wifiData: any;
}

const initialState: hotel_Cf_DataType = {
  dragDropData: [],
  wifiData: {},
  maintance_disable: false,
  housekeeping_disable: false,
  Feedback_disable: false,
  RoomServices_disable: false,
};

const dragDrop = createSlice({
  name: "dragDrop",
  initialState,
  reducers: {
    insertDragDrop: (state: hotel_Cf_DataType, action) => {
      state.dragDropData = action.payload;
    },
    insertMaintance_disable: (state: hotel_Cf_DataType, action) => {
      state.maintance_disable = action.payload;
    },
    insertHousekeeping_Disable: (state: hotel_Cf_DataType, action) => {
      state.housekeeping_disable = action.payload;
    },
    insertFeedback_Disable: (state: hotel_Cf_DataType, action) => {
      state.Feedback_disable = action.payload;
    },
    insertRoomServices_Disable: (state: hotel_Cf_DataType, action) => {
      state.RoomServices_disable = action.payload;
    },
    insertWifiData: (state: hotel_Cf_DataType, action) => {
      state.wifiData = action.payload;
    },
  },
});

export const {
  insertDragDrop,
  insertMaintance_disable,
  insertWifiData,
  insertHousekeeping_Disable,
  insertFeedback_Disable,
  insertRoomServices_Disable,
} = dragDrop.actions;

export const getdragDropData = (state: RootState) =>
  state.dragDrop.dragDropData;

export const getMaintance_disable = (state: RootState) =>
  state.dragDrop.maintance_disable;
export const getHousekeeping_disable = (state: RootState) =>
  state.dragDrop.housekeeping_disable;

export const getFeedback_disable = (state: RootState) =>
  state.dragDrop.Feedback_disable;

export const getRoomServices_disable = (state: RootState) =>
  state.dragDrop.RoomServices_disable;

export const getWifiData = (state: RootState) => state.dragDrop.wifiData;

export default dragDrop.reducer;
