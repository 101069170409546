import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
export interface posType {
  onChosenOrder: any;
  servicesOrderTable: any;
  temporyOrderLine: any;
  restaurantsChosenOrder: any;
  posSideBar: boolean;
  openItemEditDialog: {
    open: boolean;
    name: string;
  };
}

const initialState: posType = {
  onChosenOrder: [],
  servicesOrderTable: {},
  temporyOrderLine: {},
  restaurantsChosenOrder: {},
  posSideBar: false,
  openItemEditDialog: {
    open: false,
    name: "Item Availability",
  },
};

const Pos = createSlice({
  name: "Pos",
  initialState,
  reducers: {
    insertOnChosenOrder: (state: posType, action: PayloadAction<any>) => {
      state.onChosenOrder = action.payload;
    },
    insertServicesTable: (state: any, action: PayloadAction<any>) => {
      state.servicesOrderTable = action.payload;
    },
    insertTemporyOrderLine: (state: posType, action: PayloadAction<any>) => {
      state.temporyOrderLine = action.payload;
    },
    insertRestaurantsChosenOrder: (
      state: posType,
      action: PayloadAction<any>
    ) => {
      state.restaurantsChosenOrder = action.payload;
    },
    insetPosSideBar: (state: posType) => {
      state.posSideBar = !state.posSideBar;
    },
    insertOpenItemEditDialog: (state: posType, action: PayloadAction<any>) => {
      state.openItemEditDialog = action.payload;
    },
  },
});

export const {
  insetPosSideBar,
  insertOnChosenOrder,
  insertServicesTable,
  insertTemporyOrderLine,
  insertRestaurantsChosenOrder,
  insertOpenItemEditDialog,
} = Pos.actions;

export const onChosenData: any = (state: RootState) => state.Pos.onChosenOrder;

export const servicesTable: any = (state: RootState) =>
  state.Pos.servicesOrderTable;

export const orderLineValue: any = (state: RootState) =>
  state.Pos.temporyOrderLine;

export const posSidebarvalue: any = (state: RootState) => state.Pos.posSideBar;

export const restaurantsChosenData: any = (state: RootState) =>
  state.Pos.restaurantsChosenOrder;
export const getOpenEditItemDialog: any = (state: RootState) =>
  state.Pos.openItemEditDialog;

export default Pos.reducer;
