import { createSlice } from "@reduxjs/toolkit";

export interface roomServiceChartType {
  isFristTimeRangeOpen: boolean;
}

const initialState: roomServiceChartType = {
  isFristTimeRangeOpen: true,
};

const roomServiceChart = createSlice({
  name: "roomServiceChart",
  initialState,
  reducers: {
    setIsFirstTimeRangeOpen: (state, action: any) => {
      state.isFristTimeRangeOpen = action.payload;
    },
  },
});

export const { setIsFirstTimeRangeOpen } = roomServiceChart.actions;

export const getIsFirstTimeRangeOpen: any = (state: any) =>
  state.roomServiceChart.isFristTimeRangeOpen;

export default roomServiceChart.reducer;
