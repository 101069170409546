import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  selectedStatus: string;
  selectedA11yProps: string;
  skip: boolean;
  fetch: boolean;
}

const initialState: initialStateType = {
  selectedStatus: "All",
  selectedA11yProps: "",
  skip: false,
  fetch: false,
};

const selectedStatusSlice = createSlice({
  name: "selectedStatusSlice",
  initialState,
  reducers: {
    insertSelectedStatus: (state: initialStateType, action: any) => {
      state.selectedStatus = action.payload;
    },
    insertA11yProps: (state: initialStateType, action: any) => {
      state.selectedA11yProps = action.payload;
    },
    insertIsSkip: (state: initialStateType, action: { payload: boolean }) => {
      state.skip = action.payload;
    },
    insertIsFetch: (state: initialStateType, action: { payload: boolean }) => {
      state.fetch = action.payload;
    },
  },
});

export const {
  insertSelectedStatus,
  insertA11yProps,
  insertIsFetch,
  insertIsSkip,
} = selectedStatusSlice.actions;

export const selectedStatusValue: any = (state: RootState) =>
  state.selectedStatusSlice.selectedStatus;
export const A11yPropsValue = (state: RootState) =>
  state.selectedStatusSlice.selectedA11yProps;

export const getIsSkip = (state: RootState) => state.selectedStatusSlice.skip;
export const getIsFetch = (state: RootState) => state.selectedStatusSlice.fetch;

export default selectedStatusSlice.reducer;
