import { createSlice } from "@reduxjs/toolkit";
import { printerType } from "../../lib/types";

export interface PrinterInitialType {
  printer: printerType;
}

const initialState = {
  printer: null,
};

const PrinterSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    insertPrinter: (state, action) => {
      state.printer = action.payload;
    },
  },
});

export const { insertPrinter } = PrinterSlice.actions;

export const getPrinter = (state: any) => state.Printer.printer;

export default PrinterSlice.reducer;
