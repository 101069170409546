import { createSlice } from "@reduxjs/toolkit";
import { serviceOrderType } from "../../lib/types";

export interface ServiceOrderInitialStateType {
  serviceOrders: any[];
  lastHalfHoursServiceOrders: any[];
  serviceOrderSignal: number;
  temporyUpdateOrders: any;
}

const initialState: ServiceOrderInitialStateType = {
  serviceOrders: [],
  lastHalfHoursServiceOrders: [],
  temporyUpdateOrders: {},
  serviceOrderSignal: 0,
};

const ServiceOrders = createSlice({
  name: "serviceOrders",
  initialState,
  reducers: {
    setServiceorders: (state, action) => {
      state.serviceOrders = action.payload;
    },
    insertServiceOrder: (state, action) => {
      state.serviceOrders = [...state.serviceOrders, action.payload];
    },
    updateServiceOrder: (state, action) => {
      state.serviceOrders = state.serviceOrders.map((serviceOrder) =>
        serviceOrder.id === action.payload.id ? action.payload : serviceOrder
      );
    },
    removeServiceOrder: (state, action) => {
      state.serviceOrders = state.serviceOrders.filter(
        (serviceOrder) => serviceOrder.id !== action.payload.id
      );
    },
    insertServiceOrderSignal: (state, action) => {
      state.serviceOrderSignal = action.payload;
    },
    insertUpdateOrder: (state, action) => {
      state.temporyUpdateOrders = action.payload;
    },
    insertLastHalfHourServiceData: (state, action) => {
      state.lastHalfHoursServiceOrders = action.payload;
    },
  },
});

export const {
  insertServiceOrder,
  setServiceorders,
  updateServiceOrder,
  removeServiceOrder,
  insertServiceOrderSignal,
  insertUpdateOrder,
  insertLastHalfHourServiceData,
} = ServiceOrders.actions;

export const getServiceOrders = (state: any) =>
  state.ServiceOrders.serviceOrders;
export const getServiceOrderSignal = (state: any) =>
  state.ServiceOrders.serviceOrderSignal;
export const getUpdateServiceOrder = (state: any) =>
  state.ServiceOrders.temporyUpdateOrders;
export const getLastHalfHoursServiceOrder = (state: any) =>
  state.ServiceOrders.lastHalfHoursServiceOrders;

export default ServiceOrders.reducer;
