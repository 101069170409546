/* eslint-disable @next/next/no-sync-scripts */
"use client";
import { ErrorBoundary } from "react-error-boundary";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { RootState, store } from "../redux/store";
import { Provider } from "react-redux";
import { NextPage } from "next";
import { ReactElement, ReactNode, useContext, useEffect } from "react";

import { useRouter } from "next/router";
import { persistStore } from "redux-persist";
import { appWithTranslation } from "next-i18next";
import { AlertProvider } from "../components/ExpireAlert";

import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";
import Head from "next/head";
import GetInfo from "../_helper/GetInfo";
import CFError from "../components/invoice/error/CFError";
import AFError from "../components/invoice/error/AFError";
import dynamic from "next/dynamic";

const ToastContainer = dynamic(
  () =>
    import("react-toastify").then((module) => {
      return { default: module.ToastContainer };
    }),
  { ssr: false }
);

const Layout = dynamic(() => import("../components/layout/Layout"), {
  ssr: false,
});
const ThemeProvider = dynamic(
  () => import("../components/theme/ThemeProvider")
);
const ProtectAdmin = dynamic(() => import("../components/auth/ProtectAdmin"));
const ProtectStaff = dynamic(() => import("../components/auth/ProtectStaff"));
const ProtectBackOffice = dynamic(
  () => import("../components/auth/ProtectBackOffice")
);

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & {
    staff?: boolean;
    admin?: boolean;
    customerBackoffice?: boolean;
  };
};

function fallbackRender({ error }: any) {
  return <CFError />;
}
function adminFallbackRender({ error }: any) {
  return <AFError />;
}
function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const route = useRouter();
  const { pathname: path } = route;
  persistStore(store);

  if (path.startsWith("/backoffice")) {
    Component.customerBackoffice = true;
  }

  if (path.startsWith("/admin") && !path.includes("sign")) {
    Component.admin = true;
  }

  if (path.startsWith("/staff")) {
    Component.staff = true;
  }

  return getLayout(
    <>
      <Head>
        <title>innOrder</title>
        <meta name="robots" content="noindex,nofollow" />
        <meta name="robots" content="nositelinkssearchbox" />
      </Head>

      <Provider store={store}>
        <SessionProvider session={session}>
          <ThemeProvider attribute="class">
            <GetInfo>
              {Component.staff ? (
                <ProtectStaff>
                  <AlertProvider>
                    {/* <Layout> */}

                    <Component {...pageProps} />

                    <ToastContainer />
                  </AlertProvider>

                  {/* </Layout> */}
                </ProtectStaff>
              ) : Component.customerBackoffice ? (
                <ProtectBackOffice>
                  <AlertProvider>
                    <Layout>
                      <ErrorBoundary fallbackRender={adminFallbackRender}>
                        <Component {...pageProps} />
                      </ErrorBoundary>

                      <ToastContainer />
                    </Layout>
                  </AlertProvider>
                </ProtectBackOffice>
              ) : Component.admin ? (
                <ProtectAdmin>
                  <ErrorBoundary fallbackRender={adminFallbackRender}>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                  <ToastContainer />
                </ProtectAdmin>
              ) : (
                <ErrorBoundary fallbackRender={fallbackRender}>
                  {/* <Layout> */}
                  <Component {...pageProps} />
                  <ToastContainer />
                  {/* </Layout> */}
                </ErrorBoundary>
              )}
            </GetInfo>
          </ThemeProvider>
        </SessionProvider>
      </Provider>
    </>
  );
}

//@ts-ignore
export default appWithTranslation(App);
