import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DataArray } from "@mui/icons-material";

interface InitialStateProps {
  feedbackData: FeedbackDataType;
  isPdfDownloading: boolean;
  dataArrayLength: number;
}

const initialState = {
  feedbackData: null,
  isPdfDownloading: false,
  dataArrayLength: 0,
};

const feedbackBackofficeSlice = createSlice({
  name: "feebackBackoffice",

  initialState,
  reducers: {
    insertFeedback: (state, action) => {
      state.feedbackData = action.payload;
    },
    changeIsPdfDownloading: (state, action) => {
      state.isPdfDownloading = action.payload;
    },
    insertDataArrayLength: (state, action) => {
      state.dataArrayLength = action.payload;
    },
  },
});

export const { insertFeedback, changeIsPdfDownloading, insertDataArrayLength } =
  feedbackBackofficeSlice.actions;

export const getFeedbackData = (state: RootState) =>
  state.feedbackBackofficeSlice.feedbackData;
export const getIsPdfDownloading = (state: RootState) =>
  state.feedbackBackofficeSlice.isPdfDownloading;

export const getDataArrayLength = (state: RootState) =>
  state.feedbackBackofficeSlice.dataArrayLength;

export default feedbackBackofficeSlice.reducer;
