import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface PaymentIdInitialStateType {
  PaymentId: number;
}

const initialState = {
  PaymentId: 177,
};

const PaymentId = createSlice({
  name: "PaymentId",
  initialState,
  reducers: {
    setPaymentId: (state, action) => {
      state.PaymentId = action.payload;
    },
  },
});

export const { setPaymentId } = PaymentId.actions;

export const getPaymentId = (state: any) => state.PaymentId.PaymentId;

export default PaymentId.reducer;
