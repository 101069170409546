import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DrawerStateType {
  Promotions: any;
}

const initialState: DrawerStateType = {
  Promotions: [],
};

const PromotionsSlice = createSlice({
  name: "PromotionsSlice",
  initialState,
  reducers: {
    insertPromotionsData: (state, action) => {
      state.Promotions = action.payload;
    },
  },
});

export const { insertPromotionsData } = PromotionsSlice.actions;

export const PromotionsValue = (state: RootState) =>
  state.PromotionsSlice.Promotions;

export default PromotionsSlice.reducer;
