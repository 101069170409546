import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  notification_data: any;
}

const initialState: initialStateType = {
  notification_data: {},
};

const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,
  reducers: {
    insertNotificationData: (state: initialStateType, action: any) => {
      state.notification_data = action.payload;
    },
  },
});

export const { insertNotificationData } = NotificationSlice.actions;

export const getNotificationData = (state: RootState) =>
  state.NotificationSlice.notification_data;

export default NotificationSlice.reducer;
