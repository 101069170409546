import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface deleteContactType {
  contact_id: {
    id: string;
    name: string;
  };
}

const initialState: deleteContactType = {
  contact_id: {
    id: "",
    name: "",
  },
};

const deleteContactSlice = createSlice({
  name: "deleteContactSlice",
  initialState,
  reducers: {
    insertdeleteContactId: (state: deleteContactType, action: any) => {
      state.contact_id = action.payload;
    },
  },
});

export const { insertdeleteContactId } = deleteContactSlice.actions;

export const getdeleteContactId = (state: RootState) =>
  state.deleteContactSlice.contact_id;

export default deleteContactSlice.reducer;
