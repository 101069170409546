import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  popUpData: any;
}

const initialState: initialStateType = {
  popUpData: {},
};

const PopUpReducer = createSlice({
  name: "PopUpReducer",
  initialState,
  reducers: {
    insertPopUpData: (state: initialStateType, action: any) => {
      state.popUpData = action.payload;
    },
  },
});

export const { insertPopUpData } = PopUpReducer.actions;

export const getPopUpData = (state: RootState) => state.PopUpReducer.popUpData;

export default PopUpReducer.reducer;
