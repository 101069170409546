import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DrawerStateType {
  isOpen: boolean;
  openRoomService: boolean;
  openHouseKeeping: boolean;
  openMaintenance: boolean;
}

const initialState: DrawerStateType = {
  isOpen: false,
  openRoomService: false,
  openHouseKeeping: false,
  openMaintenance: false,
};

const Drawer = createSlice({
  name: "Drawer",
  initialState,
  reducers: {
    openDrawer: (state: DrawerStateType, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    insertOpenRoomService: (
      state: DrawerStateType,
      action: PayloadAction<boolean>
    ) => {
      state.openRoomService = action.payload;
    },
    insertOpenHouseKeeping: (
      state: DrawerStateType,
      action: PayloadAction<boolean>
    ) => {
      state.openHouseKeeping = action.payload;
    },
    insertOpenMaintenance: (
      state: DrawerStateType,
      action: PayloadAction<boolean>
    ) => {
      state.openMaintenance = action.payload;
    },
  },
});

export const {
  openDrawer,
  insertOpenRoomService,
  insertOpenHouseKeeping,
  insertOpenMaintenance,
} = Drawer.actions;

export const openDrawerData = (state: RootState) => state.Drawer.isOpen;

export const OpenRoomService = (state: RootState) =>
  state.Drawer.openRoomService;

export const OpenHouseKeeping = (state: RootState) =>
  state.Drawer.openHouseKeeping;

export const OpenMaintenance = (state: RootState) =>
  state.Drawer.openMaintenance;

export default Drawer.reducer;
