import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface initialState {
  geofunce_data?: any;
}

const initialState: initialState = {
  geofunce_data: {},
};

const GeofunceSlice = createSlice({
  name: " GeofunceSlice",
  initialState,
  reducers: {
    insertGeofunceData: (state: initialState, action) => {
      state.geofunce_data = action.payload;
    },
  },
});

export const { insertGeofunceData } = GeofunceSlice.actions;

export const GeofunceValue = (state: RootState) =>
  state.GeofunceSlice?.geofunce_data;

export default GeofunceSlice.reducer;
